import { createContext, useState } from "react";
import style from "../pages/Create/components/styles.options";

const CreateContext = createContext();

export default CreateContext;

export const CreateProvider = ({ children }) => {
  const [quantity, setQuantity] = useState(1);
  const [selectedColor, setSelectedColor] = useState("gray");
  const [description, setDescription] = useState("");
  const [descriptionBack, setDescriptionBack] = useState("");
  const [response, setResponse] = useState("");
  const [responseBack, setResponseBack] = useState("");
  const [imageUrls, setImageUrls] = useState([]);
  const [imageUrlsBack, setImageUrlsBack] = useState([]);
  const [imageNoBgUrls, setImageNoBgUrls] = useState([]);
  const [imageNoBgUrlsBack, setImageNoBgUrlsBack] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingBack, setIsLoadingBack] = useState(false);
  const [isLoadingbg, setIsLoadingbg] = useState(false);
  const [isLoadingBackbg, setIsLoadingBackbg] = useState(false);
  const [size, setSize] = useState(null);
  const [side, setSide] = useState("front");
  const [image, setImage] = useState("");
  const [imageBack, setImageBack] = useState("");
  const [advanced, setAdvanced] = useState(false);
  const [placeholderImg, setPlaceholderImg] = useState(true);
  const [placeholderImgBack, setPlaceholderImgBack] = useState(true);
  const [opt, setOpt] = useState(1);
  const [customImage, setCustomImage] = useState(null);
  const [customImageBack, setCustomImageBack] = useState(null);
  const [text, setText] = useState("");
  const [textBelow, setTextBelow] = useState("");
  const [textBack, setTextBack] = useState("");
  const [textBackBelow, setTextBackBelow] = useState("");
  const [selectedFont, setSelectedFont] = useState("Parisienne, cursive");
  const [selectedFontBelow, setSelectedFontBelow] = useState(
    "Parisienne, cursive"
  );
  const [selectedModel, setSelectedModel] = useState(style[0]);
  const [showModel, setShowModel] = useState(false);
  const [presetsCurrent, setPresetsCurrent] = useState("custom");

  const resetModel = async () => {
    setSelectedColor("gray");
    setDescription("");
    setDescriptionBack("");
    setImageUrls([]);
    setImageUrlsBack([]);
    setImageNoBgUrls([]);
    setImageNoBgUrlsBack([]);
    setSize(null);
    setSide("front");
    setImage(null);
    setImageBack(null);
    setText("");
    setTextBelow("");
    setTextBack("");
    setTextBackBelow("");
    setSelectedFont("Parisienne, cursive");
    setSelectedFontBelow("Parisienne, cursive");
    setPlaceholderImg(true);
    setPlaceholderImgBack(true);
    setQuantity(1);
    setCustomImage(null)
    setCustomImageBack(null)
  };

  let contextData = {
    presetsCurrent,
    setPresetsCurrent,
    showModel,
    setShowModel,
    selectedModel,
    setSelectedModel,
    quantity,
    setQuantity,
    setIsLoadingBackbg,
    isLoadingBackbg,
    imageNoBgUrlsBack,
    setImageNoBgUrlsBack,
    selectedFontBelow,
    setSelectedFontBelow,
    imageUrlsBack,
    setImageUrlsBack,
    imageNoBgUrls,
    setImageNoBgUrls,
    customImage,
    setCustomImage,
    customImageBack,
    setCustomImageBack,
    isLoadingBack,
    setIsLoadingBack,
    placeholderImgBack,
    setPlaceholderImgBack,
    responseBack,
    setResponseBack,
    imageUrls,
    setImageUrls,
    isLoading,
    setIsLoading,
    isLoadingbg,
    setIsLoadingbg,
    selectedColor,
    setSelectedColor,
    description,
    setDescription,
    descriptionBack,
    setDescriptionBack,
    response,
    setResponse,
    side,
    setSide,
    size,
    setSize,
    text,
    setText,
    selectedFont,
    setSelectedFont,
    image,
    setImage,
    textBack,
    setTextBack,
    imageBack,
    setImageBack,
    textBelow,
    setTextBelow,
    textBackBelow,
    setTextBackBelow,
    advanced,
    setAdvanced,
    opt,
    setOpt,
    placeholderImg,
    setPlaceholderImg,
    resetModel,
  };
  return (
    <CreateContext.Provider value={contextData}>
      {children}
    </CreateContext.Provider>
  );
};
