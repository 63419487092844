import React from "react";
import { Link } from "react-router-dom";

export default function NotFound() {
   return (
      <div className="container mt-5">
         <div className="text-center">
            <h2>ERROR 404</h2>
            <p>PAGE NOT FOUND</p>
            <p>
               {" "}
               GO TO <Link to={"/"}>HOME</Link>{" "}
            </p>
         </div>
      </div>
   );
}
