import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { CreateProvider } from "./context/CreateContext";
import { Auth0Provider } from "@auth0/auth0-react";
import axios from "axios";
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en.json'


TimeAgo.addDefaultLocale(en)
const  { REACT_APP_AUTH0_DOMAIN, REACT_APP_AUTH0_CLIENT_ID} = process.env;
axios.defaults.baseURL =  process.env.REACT_APP_API_BASE_URL;

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Auth0Provider
    domain={REACT_APP_AUTH0_DOMAIN} 
    clientId={REACT_APP_AUTH0_CLIENT_ID}
    // domain="dev-285spianl8i4bgor.us.auth0.com"  // Carlos
    // clientId="OL78Rvs1ye4azSATtQ2o2KzwjtXOonWI" // Carlos
    // domain="dev-i241ii10lsafaug5.us.auth0.com" // nearshoredesignco@gmail.com
    // clientId="kWj8HNqHnT3JyqpZQpLbv93G51JXAoNI" // nearshoredesignco@gmail.com
    authorizationParams={{
      redirect_uri: window.location.origin,
      audience: "https://tshirtauth0"
    }}>
      <CreateProvider>
        <App />
      </CreateProvider>
    </Auth0Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
