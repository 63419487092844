import React, { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import NotFound from "./pages/NotFound404";

const loading = (
  <div className="my-5 pt-3 text-center">
    <div className="spinner-border text-blue" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  </div>
);

const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));

function App() {
  return (
    <BrowserRouter>
      <Suspense fallback={loading}>
        <Routes>
          {/*             <Route exact path="/login" name="Login Page" element={<Login />} />
            <Route exact path="/register" name="Register Page" element={<Register />} />
          <Route exact path="/500" name="Page 500" element={<Page500 />} /> */}
          <Route exact path="/404" name="Page 404" element={<NotFound />} />
          <Route path="*" name="Home" element={<DefaultLayout />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
