const style = [
  { title: "General",img:"https://pub-3626123a908346a7a8be8d9295f44e26.r2.dev/generations/0-f1fb27a5-b52b-4f6c-9193-4a7a7c11fc3f.png", value: "sdxl", valuePromt: "" },
  {
    title: "Gouache",
    img: "https://images.nightcafe.studio/jobs/sFQXRonRvuTi64uKi2ne/sFQXRonRvuTi64uKi2ne--1--vohsa.jpg?tr=w-1600,c-at_max",
    value: "sdxl",
    valuePromt: "gouache detailed painting",
  },
  {
    title: "Cubist",
    value: "sdxl",
    img: "https://images.nightcafe.studio/jobs/5XT2kaaKT3jzGG5dTJYG/5XT2kaaKT3jzGG5dTJYG--1--pj208.jpg?tr=w-1600,c-at_max",
    valuePromt:
      "cubist painting, Neo-Cubism, layered overlapping geometry, art deco painting, Dribbble, geometric fauvism, layered geometric vector art, maximalism; V-Ray, Unreal Engine 5, angular oil painting, DeviantArt  ",
  },
  {
    title: "Artistic Portrait",
    value: "sdxl",
    img: "https://images.nightcafe.studio/jobs/lSLtP2jaCgsFxc1D3hJu/lSLtP2jaCgsFxc1D3hJu--1--6lijy.jpg?tr=w-1600,c-at_max",
    valuePromt:
      "head and shoulders portrait, 8k resolution concept art portrait by Greg Rutkowski, Artgerm, WLOP, Alphonse Mucha dynamic lighting hyperdetailed intricately detailed Splash art trending on Artstation triadic colors Unreal Engine 5 volumetric lighting",
  },
  {
    title: "Hyperreal",
    value: "realistic-vision-v13",
    img:"https://images.nightcafe.studio/jobs/HbwVgm74jpci8xE2rpWR/HbwVgm74jpci8xE2rpWR--4--rut1j.jpg?tr=w-1600,c-at_max",
    valuePromt:
      "Hyperrealistic, splash art, concept art, mid shot, intricately detailed, color depth, dramatic, 2/3 face angle, side light, colorful background",
  },
  {
    title: "Striking",
    value: "sdxl",
    img:"https://images.nightcafe.studio/jobs/oO0G5wsig6E9OmvdM2s4/oO0G5wsig6E9OmvdM2s4--2--77za2.jpg?tr=w-1600,c-at_max",
    valuePromt:
      "intricate details, HDR, beautifully shot, hyperrealistic, sharp focus, 64 megapixels, perfect composition, high contrast, cinematic, atmospheric, moody",
  },
  {
    title: "Vibrant",
    img:"https://images.nightcafe.studio/jobs/rbN5uTxnZgjUa2Q34pb8/rbN5uTxnZgjUa2Q34pb8--2--rif7g.jpg?tr=w-1600,c-at_max",
    value: "sdxl",
    valuePromt:
      "graffiti art, splash art, street art, spray paint, oil gouache melting, acrylic, high contrast, colorful polychromatic, ultra detailed, ultra quality, CGSociety",
  },
  {
    title: "Color Painting",
    img:"https://images.nightcafe.studio/jobs/PomUaqqECNSId28nSXGa/PomUaqqECNSId28nSXGa--1--ngm3y.jpg?tr=w-1600,c-at_max",
    value: "sdxl",
    valuePromt:
      "abstract art complementary colors fine details",
  },
  {
    title: "Abstract Curves",
    value: "sdxl",
    img :"https://images.nightcafe.studio/jobs/rlMgH5HyG7F1cAxXhc2b/rlMgH5HyG7F1cAxXhc2b--1--b5hi0.jpg?tr=w-1600,c-at_max",
    valuePromt: "abstract vector fractal, wave function, Zentangle, 3d shading",
  },
  {
    title: "Pop Art",
    value: "sdxl",
    img:"https://images.nightcafe.studio/jobs/TxCjDIFA68t8HX0XjIgQ/TxCjDIFA68t8HX0XjIgQ--1--saw8x.jpg?tr=w-1600,c-at_max",
    valuePromt:
      "Screen print, pop art, splash screen art, triadic colors, digital art, 8k resolution trending on Artstation, golden ratio, symmetrical, rule of thirds, geometric bauhaus",
  },
  {
    title: "Neo Impressionist",
    value: "sdxl",
    img:"https://images.nightcafe.studio/jobs/H7hpRZ6SibFLoi9iPG2L/H7hpRZ6SibFLoi9iPG2L--1--i2jny.jpg?tr=w-1600,c-at_max",
    valuePromt:
      "neo-impressionism expressionist style oil painting, smooth post-impressionist impasto acrylic painting, thick layers of colourful textured paint",
  },
  {
    title: "B&W Portrait",
    value: "sdxl",
    img:"https://images.nightcafe.studio/jobs/tkbHlH5I998cyw7eiRoL/tkbHlH5I998cyw7eiRoL--1--ake1r.jpg?tr=w-1600,c-at_max",
    valuePromt:
      "Close up portrait, ambient light, Nikon 15mm f/1.8G, by Lee Jeffries, Alessio Albi, Adrian Kuipers",
  },
];

export default style;
